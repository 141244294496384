import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [pageUrl, setPageUrl] = useState("https://google.ca");
  const [trigger, setTrigger] = useState("Code200");
  const [email, setEmail] = useState("max_maxime_max@hotmail.com");
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    //var rootApi = window.location.origin + "/api/";
    const rootApi = "http://localhost:5001/notifyme-42186/us-central1/";
    var url = rootApi + "createNotifRule";
    axios.post(url, {
      email: email,
      trigger: trigger,
      pageUrl: pageUrl
    }).then((response) => {
      setIsLoading(false);

      alert("done");
    });
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Url</label>
          <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={pageUrl} onChange={ev => setPageUrl(ev.target.value)} />
          {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Notify me if the page</label>
          <select className="form-select" aria-label="Default select example" value={trigger} onChange={ev => setTrigger(ev.target.value)}>
            <option value="Code200">Returns a valid code (200)</option>
            <option value="Code404">Returns a not found error (404)</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Email to notify</label>
          <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={ev => setEmail(ev.target.value)} />
        </div>
        <button type="submit" className="btn btn-primary">Save</button>
      </form>
    </div>
  );
}

export default App;
